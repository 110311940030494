import { REGLEMENTATION } from '../../enum/reglementation-choix';
import { productpropertiesDocType } from '../../db/schemas/productproperties.schema';
import {
  composantDocType,
  conformiteDocType,
} from '../../db/schemas/emplacement.schema';
import { getComposantDateRenouvellement } from './getComposantDateRenouvellement';
import { isComposantDurationsValid } from './isComposantDurationsValid';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import { reglementationDocType } from '../../db/schemas/reglementation.schema';

export function getComposantBadConformiteString(
  composant: composantDocType,
  properties: productpropertiesDocType[],
  fields: productfieldDocType[],
  reglementations: reglementationDocType[],
  prefix = 'x '
): string {
  let badConformiteString = composant.conformites.reduce(
    (acc = '', conformite: conformiteDocType) => {
      if (
        conformite.id_reglementation.toString() ===
        REGLEMENTATION.AGE.toString()
      ) {
        // On ne tient pas compte des conformites avec la reglementation AGE
      } else if (!conformite.est_conforme) {
        if (acc !== '') acc += ', ';

        // Si il y a un choix de reglementation, on l'ajoute et on passe à la conformite suivante
        if (conformite.id_reglementation_choix) {
          acc += reglementations.reduce((acc, reg) => {
            reg.reglementation_choixes.forEach((regChoix) => {
              if (
                regChoix.id.toString() ===
                conformite.id_reglementation_choix?.toString()
              ) {
                acc = regChoix.libelle ?? '';
              }
            });
            return acc;
          }, '');
          return acc;
        }

        // Sinon on utilise le libelle de la reglementation
        acc += reglementations.reduce((acc, reg) => {
          if (reg.id.toString() === conformite.id_reglementation.toString()) {
            acc = reg.libelle || '';
          }
          return acc;
        }, '');
      }
      return acc;
    },
    ''
  );

  if (
    getComposantDateRenouvellement(composant, properties, fields) < new Date()
  ) {
    if (badConformiteString !== '') badConformiteString += ', ';
    badConformiteString += `Durée de vie dépassée`;
  }

  if (!isComposantDurationsValid(composant, fields)) {
    if (badConformiteString !== '') badConformiteString += ', ';
    badConformiteString += `Contrôle à effectuer`;
  }

  if (badConformiteString !== '') {
    return prefix + badConformiteString;
  }
  return '';
}
