/* eslint-disable sonarjs/no-collapsible-if */
export const isGeometryValid = (
  geometry: any
): geometry is GeoJSON.Geometry => {
  if (!geometry.type) return false;

  // Vérification des coordonnées pour les points
  if (
    geometry.type === 'Point' &&
    (!Array.isArray(geometry.coordinates) ||
      geometry.coordinates.length !== 2 ||
      typeof geometry.coordinates[0] !== 'number' ||
      typeof geometry.coordinates[1] !== 'number')
  ) {
    return false;
  }

  // Vérification des coordonnées pour les lignes
  if (geometry.type === 'LineString') {
    if (
      !Array.isArray(geometry.coordinates) ||
      geometry.coordinates.length < 2 ||
      !geometry.coordinates.every(
        (coord: string | any[]) =>
          Array.isArray(coord) &&
          coord.length === 2 &&
          typeof coord[0] === 'number' &&
          typeof coord[1] === 'number'
      )
    ) {
      return false;
    }

    // Vérifier si les deux premiers points sont identiques
    if (
      geometry.coordinates[0][0] === geometry.coordinates[1][0] &&
      geometry.coordinates[0][1] === geometry.coordinates[1][1]
    ) {
      return false;
    }
  }

  // Vérification des coordonnées pour les polygones
  if (geometry.type === 'Polygon') {
    if (
      !Array.isArray(geometry.coordinates) ||
      geometry.coordinates.length === 0 ||
      !geometry.coordinates.every(
        (ring: any[]) =>
          Array.isArray(ring) &&
          ring.length >= 3 &&
          ring.every(
            (coord) =>
              Array.isArray(coord) &&
              coord.length === 2 &&
              typeof coord[0] === 'number' &&
              typeof coord[1] === 'number'
          )
      )
    ) {
      return false;
    }
  }

  return true;
};
