import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  inject,
  AfterViewInit,
} from '@angular/core';
import { MapboxService } from './../../services/mapbox/mapbox.service';
import { retrieveCenterForInit } from './../../services/mapbox/retrieveCenterForInit';
import { Map, MapMouseEvent, LngLat, LngLatBounds } from 'mapbox-gl';
import { v4 as uuidv4 } from 'uuid';
import { handleMissingImage } from '../objet-osmap/handleMissingImage';
import { SpriteManager } from '../objet-osmap/sprite.service';
import { UgauMapConfigInput } from './UgauMapConfigInput';
import { UgauMapConfig } from './UgauMapConfig';
import { FeatureCollection, GeometryCollection, Geometry } from 'geojson';
import { addMapControls } from '../../services/mapbox/addMapControls';
import { addMapSearchGeocoder } from '../../services/mapbox/addMapSearchGeocoder';
import { addMapSources } from '../../services/mapbox/addMapSources';
import { addMapLayers } from '../../services/mapbox/addMapLayers';
import { proxyCallMap } from '../../services/mapbox/proxyCallMap';
import { isGeometryValid } from '../../utils/geometry/isGeometryValid';

@Component({
  selector: 'app-ugau-map',
  template: ` <div class="mapboxgl-map map-container" [id]="idMap"></div> `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
      }
      .map-container {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UgauMapComponent implements AfterViewInit, OnDestroy {
  private mapboxService = inject(MapboxService);
  private spriteService = inject(SpriteManager);

  map: Map | undefined = undefined;

  private isClickListenerAdded = false;
  private isSpriteInitialized = false;
  private isTooEarly = false;
  private _config: UgauMapConfig | undefined = undefined;

  @Input() maxZoom!: number;
  @Input() set config(config: UgauMapConfigInput) {
    if (
      config.geojson?.features.length === 1 &&
      (config.center?.latitude !== this._config?.center?.latitude ||
        config.center?.longitude !== this._config?.center?.longitude)
    ) {
      // Le centre bouge, on recenter la map
      this.recenterOnUpdate = true;
    } else {
      // On ne recenter pas la map
      this.recenterOnUpdate = false;
    }

    const geojson: FeatureCollection = {
      type: 'FeatureCollection',
      features: [...(config.geojson?.features ?? [])],
    };

    this._config = {
      lockZoomOnFitBounds: config.lockZoomOnFitBounds ?? false,
      disableControl: config.disableControl ?? false,
      disablePointOnLine: config.disablePointOnLine ?? false,
      pitch: config.pitch ?? false,
      center: config.center ?? null,
      geojson: castCoordsToNumbers(geojson),
    };

    this.updateMapDatas();
  }
  @Input() idMap = uuidv4();
  @Input() recenterOnUpdate = true;

  @Output() mapClick: EventEmitter<LngLat> = new EventEmitter<LngLat>();

  ngOnDestroy(): void {
    if (this.map) {
      // Remove all event listeners
      this.map.off('click', this.handleMapClick);
      this.map.off('styleimagemissing', this.handleMapMissingImage);

      // Clean up the map instance itself
      this.mapboxService.removeMap(this.idMap);
      this.map = undefined;
    }

    // Clean up the map container if needed
    const container = document.getElementById(this.idMap);
    if (container) {
      container.innerHTML = ''; // Clear any leftover content to reset the state
    }

    this.mapClick.complete();
  }

  ngAfterViewInit(): void {
    this.onMapInit();

    if (this.isTooEarly && this.map) {
      proxyCallMap({
        map: this.map,
        func: () => {
          this.updateMapDatas();
        },
      });
    }
  }

  private updateMapDatas() {
    if (this.map) {
      this.doGeojsonAddOrUpdate();
      if (this.recenterOnUpdate) this.adjustMapToFitMarkersIn();
    } else {
      this.isTooEarly = true;
    }
  }

  private adjustMapToFitMarkersIn() {
    const bounds = new LngLatBounds();
    this.extendsBoundsWithGeoJson(bounds);

    if (!bounds.isEmpty() && this.map) {
      const options: any = {
        padding: { top: 50, bottom: 50, left: 50, right: 50 },
        animate: false,
      };
      if (this._config?.lockZoomOnFitBounds) {
        options.maxZoom = this.map.getZoom();
      } else {
        if (this._config?.center?.zoom) {
          if (this._config.disableControl) {
            options.minZoom = this._config.center.zoom;
          } else {
            options.maxZoom = this._config.center.zoom;
          }
        }
      }

      this.map.fitBounds(bounds, options);
    }
  }

  private extendsBoundsWithGeoJson(bounds: LngLatBounds) {
    this._config?.geojson.features.forEach((feature) => {
      const isValid = isGeometryValid(feature.geometry);
      if (isValid) {
        if (feature.geometry.type === 'Point') {
          this.extendPoint(bounds, feature.geometry.coordinates);
        }

        if (feature.geometry.type === 'LineString') {
          feature.geometry.coordinates.forEach((coord) => {
            this.extendPoint(bounds, coord);
          });
        }

        if (feature.geometry.type === 'MultiLineString') {
          feature.geometry.coordinates.forEach((line) => {
            line.forEach((coord) => {
              this.extendPoint(bounds, coord);
            });
          });
        }

        if (feature.geometry.type === 'Polygon') {
          feature.geometry.coordinates.forEach((ring) => {
            ring.forEach((coord) => {
              this.extendPoint(bounds, coord);
            });
          });
        }
      }
    });
  }

  private extendPoint(bounds: LngLatBounds, coord: number[]) {
    if (
      Array.isArray(coord) &&
      coord.length === 2 &&
      typeof coord[0] === 'number' &&
      typeof coord[1] === 'number' &&
      !isNaN(coord[0]) &&
      !isNaN(coord[1])
    ) {
      bounds.extend([coord[0], coord[1]]);
    }
  }

  private doGeojsonAddOrUpdate() {
    if (!this.map) return;
    if (!this._config) return;
    const type = this._config.disablePointOnLine
      ? 'emplacement_detail'
      : 'emplacement_selector';

    proxyCallMap({
      map: this.map,
      func: () => {
        if (!this.map) return;
        if (!this._config) return;

        addMapSources(this._config.geojson, this.map);
        addMapLayers(this.map, type);
      },
    });
  }

  hasTryOnce = false;

  onMapInit() {
    this.initMap();
    this.listenClickOnMap();
  }

  initMap() {
    const container = document.getElementById(this.idMap);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!container || !this._config) {
      return false;
    }

    if (!this._config.center)
      this._config.center = retrieveCenterForInit(this.idMap);

    this.map = this.mapboxService.initMapboxMap(
      this.idMap,
      container,
      [this._config.center.longitude, this._config.center.latitude],
      {
        interactive: !this._config.disableControl,
        ...(this._config.center.zoom && { zoom: this._config.center.zoom }),
        ...(this._config.pitch && { pitch: 1, minPitch: 1 }),
        ...(this.maxZoom && { maxZoom: this.maxZoom }),
      }
    );

    this.map.on('style.load', () => {
      if (this.map && !this.isSpriteInitialized) {
        this.isSpriteInitialized = true;

        const currentStyle = this.map.getStyle();
        const newSprite = this.spriteService.getSpriteForMapbox();

        // Check if the sprite needs updating to prevent unnecessary setStyle calls
        if (currentStyle.sprite !== newSprite) {
          this.map.setStyle({
            ...currentStyle,
            sprite: newSprite,
          });
        }
      }
    });

    if (!this._config.disableControl) {
      addMapSearchGeocoder(this.map);
      this.mapboxService.addMapStylesSwitcher(this.map);
    }
    addMapControls(
      this.map,
      !this._config.disableControl,
      !this._config.disableControl
    );

    this.map.on('styleimagemissing', this.handleMapMissingImage);
  }

  private handleMapMissingImage = (e: any) => {
    this.map && handleMissingImage(this.map, e, this.idMap);
  };

  listenClickOnMap() {
    if (!this.map) return;

    if (!this.isClickListenerAdded) {
      this.map.on('click', this.handleMapClick);
      this.isClickListenerAdded = true;
    }
  }

  handleMapClick = (event: MapMouseEvent) => {
    this.mapClick.emit(event.lngLat);
  };
}

function castCoordsToNumbers(
  featureCollection: FeatureCollection
): FeatureCollection {
  // Fonction récursive pour caster les coordonnées
  function castCoordinates(coords: any): any {
    if (Array.isArray(coords)) {
      return coords.map(castCoordinates);
    }
    return +coords; // Convertit la valeur en nombre
  }

  // Parcourir chaque feature dans la FeatureCollection
  featureCollection.features.forEach((feature) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (feature.geometry) {
      if (isGeometryCollection(feature.geometry)) {
        feature.geometry.geometries.forEach((geometry) => {
          if ('coordinates' in geometry) {
            geometry.coordinates = castCoordinates(geometry.coordinates);
          }
        });
      } else if ('coordinates' in feature.geometry) {
        feature.geometry.coordinates = castCoordinates(
          feature.geometry.coordinates
        );
      }
    }
  });

  return featureCollection;
}

function isGeometryCollection(
  geometry: Geometry
): geometry is GeometryCollection {
  return geometry.type === 'GeometryCollection';
}
