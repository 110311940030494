/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Map } from 'mapbox-gl';
import { removeLayer } from './removeLayer';
import { removeSource } from './removeSource';
import { MapboxCustomSwitcherControl } from './MapboxCustomSwitcherControl';

const URI_lines_administratives = 'lines-administratives';
const URI_points_repere_routiers = 'points-repere-routiers';
const IGN_URL = 'https://data.geopf.fr/';
const IGN_GEO_AND_TILE = 'wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile';
const IGN_TILE =
  '&tilematrixset=PM&TileMatrix={z}&TileCol={x}&TileRow={y}&Format=image%2Fpng&style=normal&I=0&J=0&infoformat=application%2Fjson';
const CLICK_IGN_ENABLE = false;

/**
 * @const layersChoices
 * @description An array of objects representing different map layers available for selection.
 * Each object contains a title and a URI for the respective map layer.
 *
 * @property {string} title - The title of the map layer.
 * @property {string} uri - The URI identifier for the map layer.
 */
const layersChoices = [
  {
    title: 'Limite administrative',
    uri: URI_lines_administratives,
  },
  {
    title: 'Cadastre',
    uri: 'lines-cadastre',
  },
  {
    title: 'Repère routier',
    uri: URI_points_repere_routiers,
  },
  {
    title: 'Hydrographie',
    uri: 'lines-hydrographie',
  },
];

/**
 * Adds a Mapbox control to toggle various layers on the map.
 *
 * @param {Map} map - The Mapbox map instance to which the control will be added.
 *
 * This function initializes a custom control that allows users to toggle different layers on the map.
 * The layers include administrative lines, road markers, cadastre lines, hydrography lines, and buildings.
 * When a layer is toggled, it is either added to or removed from the `layersEnabled` array.
 * The control is added to the top-right corner of the map.
 *
 * The control listens for the `onOpen` event, which is triggered when a user interacts with the control.
 * Depending on the URI of the layer, the corresponding function is called to add or remove the layer from the map.
 *
 * The following layers can be toggled:
 * - Administrative lines
 * - Road markers
 * - Cadastre lines
 * - Hydrography lines
 * - Buildings (disabled by default)
 *
 * The `options` object contains the event listeners for the control.
 * The `layersEnabled` array keeps track of which layers are currently enabled.
 */
export function addMapIgnSandLToggler(map: Map) {
  const layersEnabled: any[] = [];
  const options = {
    eventListeners: {
      onOpen: (event: MouseEvent) => {
        if (event.target instanceof Element) {
          const uri = event.target.getAttribute('data-uri')?.replace(/"/g, '');
          if (!uri) return true;

          const alreadyEnabled = layersEnabled.find((layer) => layer === uri);
          if (!alreadyEnabled) {
            layersEnabled.push(uri);
          } else {
            layersEnabled.splice(layersEnabled.indexOf(uri), 1);
          }

          if (uri === URI_lines_administratives) {
            addMapAdministrativeLines(map, !alreadyEnabled ? false : true);
          } else if (uri === URI_points_repere_routiers) {
            addMapRepereRoutier(map, !alreadyEnabled ? false : true);
          } else if (uri === 'lines-cadastre') {
            addMapCadastre(map, !alreadyEnabled ? false : true);
          } else if (uri === 'lines-hydrographie') {
            addMapWaterLines(map, !alreadyEnabled ? false : true);
          } else if (uri === 'buildings') {
            addBuildings(map, !alreadyEnabled ? false : true);
          }
        }
        return true;
      },
    },
  };
  map.addControl(
    new MapboxCustomSwitcherControl(layersChoices, options),
    'top-right'
  );
}

/**
 * Loads an IGN raster source and layer into the provided Mapbox map instance.
 * If the map's style is already loaded, the source and layer are added immediately.
 * Otherwise, the source and layer are added once the map's 'load' event is fired.
 *
 * @param {Map} map - The Mapbox map instance to which the source and layer will be added.
 * @param {string} sourceLib - The identifier for the source library.
 * @param {string} url - The URL of the raster source.
 */
export function loadIgnRasterSourceAndLayer(
  map: Map,
  sourceLib: string,
  url: string
) {
  if (map.isStyleLoaded()) {
    mapAddSandLIgnRaster(map, sourceLib, url);
    return;
  }
  map.on('load', () => {
    mapAddSandLIgnRaster(map, sourceLib, url);
  });
}

/**
 * Adds or removes water lines on the map using Mapbox.
 *
 * @param {Map} map - The Mapbox map instance.
 * @param {boolean} [remove=false] - If true, removes the water lines; otherwise, adds them.
 * @returns {void}
 */
export function addMapWaterLines(map: Map, remove = false) {
  const sourceLib = 'points-hydrographiques';
  if (remove) return mapRemoveSandLIgnRaster(map, sourceLib);
  const url = IGN_URL + IGN_GEO_AND_TILE;
  '&layer=HYDROGRAPHY.HYDROGRAPHY' + IGN_TILE;

  loadIgnRasterSourceAndLayer(map, sourceLib, url);
}

/**
 * Adds or removes administrative lines on the given Mapbox map.
 *
 * @param {Map} map - The Mapbox map instance to which the administrative lines will be added or from which they will be removed.
 * @param {boolean} [remove=false] - If true, the administrative lines will be removed from the map. If false, the administrative lines will be added to the map.
 * @returns {void}
 */
function addMapAdministrativeLines(map: Map, remove = false) {
  if (remove) return mapRemoveSandLIgnRaster(map, URI_lines_administratives);

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=LIMITES_ADMINISTRATIVES_EXPRESS.LATEST' +
    IGN_TILE;

  loadIgnRasterSourceAndLayer(map, URI_lines_administratives, url);
}

/**
 * Adds or removes the cadastral map layer to/from the provided Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the cadastral layer will be added or removed.
 * @param {boolean} [remove=false] - A flag indicating whether to remove the cadastral layer.
 *                                   If true, the layer will be removed; otherwise, it will be added.
 *
 * @returns {void}
 */
export function addMapCadastre(map: Map, remove = false) {
  const sourceLib = 'base-adresse-nationale-gouv';
  const handleClick = (e: any) => {
    getInfoParcelleFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
      (data) => {
        console.log(data);
      }
    );
  };

  if (remove) {
    if (CLICK_IGN_ENABLE) map.off('click', handleClick);
    return mapRemoveSandLIgnRaster(map, sourceLib);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS' +
    IGN_TILE;
  loadIgnRasterSourceAndLayer(map, sourceLib, url);

  if (CLICK_IGN_ENABLE) {
    map.off('click', handleClick);
    map.on('click', handleClick);
  }
}

/**
 * Adds a raster source and layer to the provided Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the raster source and layer will be added.
 * @param {string} sourceLib - The identifier for the source library.
 * @param {string} url - The URL of the raster tiles.
 *
 * @example
 * ```typescript
 * mapAddSandLIgnRaster(map, 'mySourceLib', 'https://example.com/tiles/{z}/{x}/{y}.png');
 * ```
 */
export function mapAddSandLIgnRaster(map: Map, sourceLib: string, url: string) {
  map.addSource(sourceLib, {
    type: 'raster',
    tiles: [url],
    tileSize: 256,
    minzoom: 6,
    maxzoom: 18,
  });
  map.addLayer(
    {
      id: sourceLib + '-layer',
      type: 'raster',
      source: sourceLib,
      paint: {
        'raster-opacity': 0.5,
      },
    },
    'z-index-1'
  );
}
/**
 * Removes a specified raster layer and its source from the Mapbox map.
 *
 * @param map - The Mapbox map instance from which the layer and source will be removed.
 * @param libelle - The label used to identify the layer and source to be removed.
 */
export function mapRemoveSandLIgnRaster(map: Map, libelle: string) {
  removeLayer(map, `${libelle}-layer`);
  removeSource(map, `${libelle}`);
}

// TODO : Ne fonctionne qu'avec mapbox light-v10
export function addBuildings(map: Map, remove = false) {
  const sourceLib = 'buildings';
  if (remove) {
    map.removeLayer(sourceLib);
    return;
  }

  if (map.isStyleLoaded()) {
    doAddBuildings(map, sourceLib);
    return;
  }
  map.on('load', () => {
    doAddBuildings(map, sourceLib);
  });
}

export function doAddBuildings(map: Map, sourceLib: string) {
  const layers = map.getStyle().layers;
  const labelLayerId = layers.find(
    (layer) => layer.type === 'symbol' && layer.layout?.['text-field']
  )?.id;

  map.addLayer(
    {
      id: sourceLib,
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: 15,
      paint: {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'height'],
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'min_height'],
        ],
        'fill-extrusion-opacity': 0.6,
      },
    },
    labelLayerId
  );
}

let funcHandleRepereRouter: any;
export function addMapRepereRoutier(map: Map, remove = false) {
  if (!funcHandleRepereRouter) {
    funcHandleRepereRouter = (e: any) => {
      getInfoPrFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
        (data) => {
          console.log(data);
        }
      );
    };
  }
  const sourceLib = URI_points_repere_routiers;

  if (remove) {
    console.log('remove repere routier');
    if (CLICK_IGN_ENABLE) map.off('click', funcHandleRepereRouter);
    return mapRemoveSandLIgnRaster(map, sourceLib);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=TRANSPORTNETWORK.COMMONTRANSPORTELEMENTS.MARKERPOST' +
    IGN_TILE;
  loadIgnRasterSourceAndLayer(map, sourceLib, url);

  if (CLICK_IGN_ENABLE) {
    map.off('click', funcHandleRepereRouter);
    map.on('click', funcHandleRepereRouter);
  }
}

export function getInfoPrFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlPRinfoPopupFromIgn(lat, lng, zoom);
  return fetch(url).then((response) => response.text());
}

export function getInfoParcelleFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlCadastrePopupFromIgn(lat, lng, zoom);
  return fetch(url).then((response) => response.text());
}

export function getUrlPRinfoPopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  const z = Math.floor(zoom);
  // console.log('Zoom:', z, zoom);

  // Get the tile X and Y (assuming you have these functions)
  const tileX = lon2tile(lng, z);
  const tileY = lat2tile(lat, z);
  // console.log('Tile X:', tileX, 'Tile Y:', tileY);

  // Convert tile indices back to lat/lng of the top-left corner of the tile
  const tileCornerLng = tile2lon(tileX, z);
  const tileCornerLat = tile2lat(tileY, z);

  // Calculate the position within the tile
  const tileSizeInDegreesLng = tile2lon(tileX + 1, z) - tileCornerLng;
  const tileSizeInDegreesLat = tile2lat(tileY + 1, z) - tileCornerLat;

  const i = Math.floor((256 * (lng - tileCornerLng)) / tileSizeInDegreesLng);
  const j = Math.floor((256 * (lat - tileCornerLat)) / tileSizeInDegreesLat);

  // console.log('Calculated I:', i, 'Calculated J:', j);

  return `https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetFeatureInfo&LAYER=TRANSPORTNETWORK.COMMONTRANSPORTELEMENTS.MARKERPOST_VISU&TILECOL=${tileX}&TILEROW=${tileY}&TILEMATRIX=${z}&TILEMATRIXSET=PM&FORMAT=image/png&STYLE=normal&INFOFORMAT=application/json&I=${i}&J=${j}`;
}

function lon2tile(lon: number, zoom: number) {
  return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
}
function lat2tile(lat: number, zoom: number) {
  return Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
      2) *
      Math.pow(2, zoom)
  );
}

function tile2lon(x: number, z: number) {
  return (x / Math.pow(2, z)) * 360 - 180;
}

function tile2lat(y: number, z: number) {
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
}

export function getUrlCadastrePopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  const z = Math.floor(zoom);

  // Obtenir les indices de tuile X et Y
  const tileX = lon2tile(lng, z);
  const tileY = lat2tile(lat, z);

  // Convertir les indices de tuile en coordonnées lat/lng du coin supérieur gauche
  const tileCornerLng = tile2lon(tileX, z);
  const tileCornerLat = tile2lat(tileY, z);

  // Calculer la position dans la tuile
  const tileSizeInDegreesLng = tile2lon(tileX + 1, z) - tileCornerLng;
  const tileSizeInDegreesLat = tile2lat(tileY + 1, z) - tileCornerLat;

  const i = Math.floor((256 * (lng - tileCornerLng)) / tileSizeInDegreesLng);
  const j = Math.floor((256 * (lat - tileCornerLat)) / tileSizeInDegreesLat);

  // Construire l'URL pour les parcelles cadastrales
  return `https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetFeatureInfo&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&TILECOL=${tileX}&TILEROW=${tileY}&TILEMATRIX=${z}&TILEMATRIXSET=PM&FORMAT=image/png&STYLE=normal&INFOFORMAT=application/json&I=${i}&J=${j}`;
}
