import { enableGenericLayer } from './enableGenericLayer';
import { handleMouseEnter } from './handleMouseEnter';
import { handleMouseleave } from './handleMouseleave';
import { mapboxLayerOptions } from './mapbox.interface';
import { Map, MapMouseEvent, EventData } from 'mapbox-gl';

/**
 * Adds or updates a layer on the Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance where the layer will be added or updated.
 * @param {mapboxLayerOptions} layer - The layer options to be added or updated on the map.
 * @param {Function} [clickCallback] - Optional callback function to handle click events on the layer.
 */
export function layerAddOrUpdate(
  map: Map,
  layer: mapboxLayerOptions,
  clickCallback?: any
) {
  enableGenericLayer(map, layer);
  if (clickCallback) {
    setupClickEventListeners(map, { clickCallback }, [layer.id]);
  }
}

/**
 * Sets up click event listeners on a Mapbox map instance for specified layers.
 *
 * @param {Map} map - The Mapbox map instance.
 * @param {any} options - Options object containing callback functions.
 * @param {string[]} layersId - Array of layer IDs to attach event listeners to.
 *
 * @remarks
 * This function attaches a click event listener to the map that triggers the provided
 * click callback function when a feature from the specified layers is clicked. It also
 * sets up mouse enter and leave event listeners for the specified layers.
 *
 * @example
 * ```typescript
 * setupClickEventListeners(map, {
 *   clickCallback: (e, feature) => {
 *     console.log('Feature clicked:', feature);
 *   }
 * }, ['layer1', 'layer2']);
 * ```
 */
export function setupClickEventListeners(
  map: Map,
  options: any,
  layersId: string[]
) {
  const func = (e: MapMouseEvent & EventData) => {
    const features = map.queryRenderedFeatures(e.point);
    const clickedFeature = features.find((f) => layersId.includes(f.layer.id));
    if (clickedFeature) {
      options.clickCallback?.(e, clickedFeature);
      return;
    }
    options.clickCallback?.(e);
  };

  // Écouteur pour le clic
  map.off('click', func);
  map.on('click', func);

  layersId.forEach((layerId) => {
    map.on('mouseenter', layerId, handleMouseEnter(map));
    map.on('mouseleave', layerId, handleMouseleave(map));
  });
}
