import { WAIT_DURATION } from '../enum/WAIT_DURATION';

export async function fetchWithTimeout(url: string, options: any = {}) {
  // Timeout par défault de 30s
  const { timeout = 30000 } = options;

  const abortCtrl = new AbortController();
  const setTimeoutId = setTimeout(() => abortCtrl.abort(), timeout);

  const response = await fetch(url, {
    ...options,
    signal: abortCtrl.signal,
  });
  clearTimeout(setTimeoutId);
  return response;
}

export function downloadArrayAsCSV(array: any[], filename: string) {
  const csv = 'data:text/csv;charset=utf-8,' + convertToCSV(array);
  forceDownload(csv, filename, 'csv');
}

export function convertToCSV(arr: any) {
  const array = [Object.keys(arr[0])].concat(arr);
  return array
    .map((it: any) => {
      const tata = (Object as any).values(it).map((el: any) => el);
      return '"' + tata.join('";"') + '"';
    })
    .join('\n');
}

export function convertToCSVnoEscape(arr: any, includeHeader = true) {
  let array;
  if (includeHeader) {
    array = [Object.keys(arr[0])].concat(arr);
  } else {
    array = arr;
  }
  return array
    .map((it: any) => {
      const tata = (Object as any).values(it);
      return '"' + tata.join('";"') + '"';
    })
    .join('\n');
}

export function forceDownload(
  content: string,
  filename: string,
  extension: string = '.png',
  targetBlank: boolean = false
) {
  return new Promise((resolve) => {
    const link = document.createElement('a');
    link.setAttribute('href', content);
    if (targetBlank) {
      link.setAttribute('target', '_blank');
    }
    link.setAttribute('download', filename + extension);
    document.body.appendChild(link); // Required for FF
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
      resolve(null);
    }, WAIT_DURATION.SHORT);
  });
}

export function contains(element: string, search: string) {
  return element.toLowerCase().includes(search.toLowerCase());
}

export function generateTimeName(filename: string, separator = '_') {
  const date = new Date().toLocaleDateString('fr-FR');
  const time = new Date().toLocaleTimeString('fr-FR');
  return filename + separator + date + separator + time;
}

export function getBase64Size(base64String: string) {
  let padding = 0;
  if (base64String.endsWith('==')) padding = 2;
  else if (base64String.endsWith('=')) padding = 1;

  return (base64String.length * 3) / 4 - padding;
}
