import { getDefaultLayoutClusterCount } from '../../components/objet-osmap/getDefaultLayoutClusterCount';
import { getDefaultPaintCluster } from '../../components/objet-osmap/getDefaultPaintCluster';
import { getDefaultPaintClusterCount } from '../../components/objet-osmap/getDefaultPaintClusterCount';
import {
  MIN_ZOOM_LINE_OR_POLYGON,
  MAP_CLUSTER_POLYGON_COLOR,
  MAX_ZOOM_CLUSTER,
} from './mapbox-constants';
import { mapboxLayerOptions } from './mapbox.interface';

export const POLYGON_LINE_HOME: mapboxLayerOptions = {
  id: 'polygon-lines',
  source: 'polygons_source',
  type: 'line',
  layer: {
    id: 'polygon-lines',
    source: 'polygons_source',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: 'visible',
    },
    paint: {
      'line-color': '#BF93E4',
      'line-width': 5,
    },
    minzoom: MIN_ZOOM_LINE_OR_POLYGON,
  },
  zIndex: 'z-index-2',
};
export const POLYGON_FILL_HOME: mapboxLayerOptions = {
  id: 'polygon-features',
  source: 'polygons_source',
  type: 'fill',
  layer: {
    id: 'polygon-features',
    source: 'polygons_source',
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#BF93E4',
      'fill-opacity': 0.5,
    },
    minzoom: MIN_ZOOM_LINE_OR_POLYGON,
  },
  zIndex: 'z-index-2',
};

export const POLYGON_CLUSTER_HOME: mapboxLayerOptions = {
  id: 'polygon-clusters',
  source: 'polygons_points_source',
  type: 'circle',
  layer: {
    id: 'polygon-clusters',
    type: 'circle',
    source: 'polygons_points_source',
    filter: ['has', 'point_count'],
    paint: getDefaultPaintCluster(MAP_CLUSTER_POLYGON_COLOR),
    maxzoom: MAX_ZOOM_CLUSTER,
  },
  zIndex: 'z-index-2',
};
export const POLYGON_CLUSTER_COUNT_HOME: mapboxLayerOptions = {
  id: 'polygon-cluster-count',
  source: 'polygons_points_source',
  type: 'symbol',
  layer: {
    id: 'polygon-cluster-count',
    type: 'symbol',
    source: 'polygons_points_source',
    filter: ['has', 'point_count'],
    layout: getDefaultLayoutClusterCount(),
    paint: getDefaultPaintClusterCount(),
    maxzoom: MAX_ZOOM_CLUSTER,
  },
  zIndex: 'z-index-2',
};
