<div *ngIf="store$ | async as store">
  <div class="statistique-first">
    <mat-tab-group #matTabGroup>
      <mat-tab
        [label]="item.key.toUpperCase()"
        *ngFor="
          let item of store | keyvalue: compareOrderLocal;
          trackBy: trackByKey
        "
      >
        <ng-template matTabContent>
          <ng-container
            [ngTemplateOutlet]="tabTemplate"
            [ngTemplateOutletContext]="{
              value: item.value,
            }"
          ></ng-container>

          <ng-container *ngIf="(item.value.childrens | json) === '{}'">
            <ng-container
              [ngTemplateOutlet]="diagTemplate"
              [ngTemplateOutletContext]="{
                key: item.key,
                value: item.value,
              }"
            ></ng-container>
            <div class="elem-list">
              <mat-accordion>
                <ng-container
                  [ngTemplateOutlet]="listTemplate"
                  [ngTemplateOutletContext]="{
                    key: item.key,
                    value: item.value,
                    confkey: 'nonConforme',
                    open: true,
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="listTemplate"
                  [ngTemplateOutletContext]="{
                    key: item.key,
                    value: item.value,
                    confkey: 'conforme',
                    open: true,
                  }"
                ></ng-container>
              </mat-accordion>
            </div>
          </ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #diagTemplate let-key="key" let-value="value">
    <div class="diagTemplate">
      <div class="stat-list">
        <mat-list>
          <mat-list-item>
            {{ key.toUpperCase() }}
          </mat-list-item>
          <mat-list-item i18n>
            {{ value.count }} élément(s)
            <span *ngIf="canShowPrices$ | async"
              >pour un total {{ value.total | formatnumber: true }}</span
            >
          </mat-list-item>
          <mat-list-item>
            <span class="carre bg-green"></span>
            <span i18n>
              {{ value.conforme.count }} conforme
              <span *ngIf="canShowPrices$ | async"
                >({{ value.conforme.total | formatnumber: true }})</span
              >
            </span>
          </mat-list-item>
          <mat-list-item>
            <span class="carre bg-red"></span>
            <span i18n>
              {{ value.nonConforme.count }} non conforme
              <span *ngIf="canShowPrices$ | async"
                >({{ value.nonConforme.total | formatnumber: true }})</span
              >
            </span>
          </mat-list-item>
        </mat-list>
      </div>
      <div>
        <app-ugau-statistique-chart-donut
          [inputDatas]="value"
        ></app-ugau-statistique-chart-donut>
      </div>
    </div>
  </ng-template>

  <ng-template
    #listTemplate
    let-key="key"
    let-value="value"
    let-confkey="confkey"
    let-open="open"
  >
    <app-ugau-statistique-list
      [value]="value"
      [confkey]="confkey"
      [open]="open"
      (showConcernedEmplacement)="showEmplacement($event)"
      (showConcernedComposants)="showComposants($event)"
      [showPrices]="(canShowPrices$ | async) ?? false"
    ></app-ugau-statistique-list>
  </ng-template>

  <ng-template #tabTemplate let-value="value">
    <mat-tab-group #matTabSubGroup>
      <mat-tab
        [label]="subsubitem.key.toUpperCase()"
        *ngFor="
          let subsubitem of value.childrens | keyvalue: compareOrderLocal;
          trackBy: trackByKey
        "
      >
        <ng-template matTabContent>
          <ng-container
            [ngTemplateOutlet]="tabTemplate"
            [ngTemplateOutletContext]="{
              value: subsubitem.value,
            }"
          ></ng-container>

          <ng-container *ngIf="(subsubitem.value.childrens | json) === '{}'">
            <ng-container
              [ngTemplateOutlet]="diagTemplate"
              [ngTemplateOutletContext]="{
                key: subsubitem.key,
                value: subsubitem.value,
              }"
            ></ng-container>
            <div class="elem-list">
              <mat-accordion>
                <ng-container
                  [ngTemplateOutlet]="listTemplate"
                  [ngTemplateOutletContext]="{
                    key: subsubitem.key,
                    value: subsubitem.value,
                    confkey: 'nonConforme',
                    open: true,
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="listTemplate"
                  [ngTemplateOutletContext]="{
                    key: subsubitem.key,
                    value: subsubitem.value,
                    confkey: 'conforme',
                    open: true,
                  }"
                ></ng-container>
              </mat-accordion>
            </div>
          </ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
