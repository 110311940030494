import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl, { Map } from 'mapbox-gl';
import { mapboxAccessToken } from '../../utils/mapbox-api-utils.service';
import { generateGeocoderResult } from './generateGeocoderResult';

/**
 * Adds a Mapbox Geocoder control to the provided map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the Geocoder control will be added.
 *
 * The Geocoder control allows users to search for locations using a search box.
 * It includes a custom local geocoder that provides additional results when the search query
 * contains a comma-separated latitude and longitude.
 *
 * The local geocoder parses the search query to extract latitude and longitude values,
 * and generates additional geocoder results based on these coordinates.
 *
 * The control is positioned at the top-left corner of the map.
 */
export function addMapSearchGeocoder(map: Map) {
  map.addControl(
    new MapboxGeocoder({
      accessToken: mapboxAccessToken,
      marker: true,
      mapboxgl: mapboxgl,
      placeholder: $localize`Chercher`,

      localGeocoder: (search: string): MapboxGeocoder.Result[] => {
        const additionalResults: MapboxGeocoder.Result[] = [];

        if (search.includes(',')) {
          const lat = parseFloat(search.split(',')[0].trim());
          const lng = parseFloat(search.split(',')[1].trim());
          if (isNaN(lat) || isNaN(lng)) return [];
          additionalResults.push(
            generateGeocoderResult(
              [lng - 0.01, lat - 0.01, lng + 0.01, lat + 0.01],
              lng,
              lat
            )
          );
          additionalResults.push(
            generateGeocoderResult(
              [lat - 0.01, lng - 0.01, lat + 0.01, lng + 0.01],
              lat,
              lng
            )
          );
        }

        return additionalResults;
      },
    }),
    'top-left'
  );
}
