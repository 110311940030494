import { v4 as uuidv4 } from 'uuid';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { Router, NavigationEnd, DefaultUrlSerializer } from '@angular/router';
import { LngLat } from 'mapbox-gl';
import { BehaviorSubject, Observable, filter, shareReplay } from 'rxjs';
import { EMPLACEMENT_STATUS } from '../../common-projects/emplacement_status';
import {
  URL_MODAL_PAGE,
  URL_PANEL_ACTION,
  URL_PANEL_DISPLAY_ACTION,
} from '../enum/url-emplacement';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../utils/localstorage-utils.service';
import {
  ModalState,
  PageState,
  RoutingStateType,
} from './routing-state.interface';
import { MapboxImplantationModeState } from '../services/mapbox/mapbox-implantation-mode-state.service';
import {
  extractModalRoutingInformation,
  extractPanelRoutingInformation as extractPanelRoutingInformation,
} from './routing-state.utils';
import { environment } from '../../environments/environment';
import { FocusService } from '../components/dialogs/focus.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingState {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private implantationModeState = inject(MapboxImplantationModeState);
  private focusService = inject(FocusService);

  private urlSerializer = new DefaultUrlSerializer();

  private routingSubject$: BehaviorSubject<RoutingStateType> =
    new BehaviorSubject<RoutingStateType>(this.loadInitialState());
  routing$: Observable<RoutingStateType> = this.routingSubject$
    .asObservable()
    .pipe(shareReplay(1));

  constructor() {
    this.listenAngularRouter();
  }

  // Always return EMPTY state
  private loadInitialState(): RoutingStateType {
    const state = getFromLocalStorage('routingState', true);
    if (state?.source_event) {
      // SPECIFIC CASE : Redo old navigation
      setTimeout(() => {
        console.log('Redo old navigation');
        this.router.navigateByUrl(state.source_event.url);
      }, 50);
    }
    return this.initialState();
  }

  private initialState(): RoutingStateType {
    const pageState = new PageState({
      page: undefined,
      action: undefined,
      id_emplacement: undefined,
      id_emplacement_photo: undefined,
      emplacement_status: undefined,
      latitude: undefined,
      longitude: undefined,
      forceOpenSearch: false,
      source_event: undefined,
    });
    return new RoutingStateType(pageState, undefined);
  }

  // Ex: /?panel=detail/6785DHJGFJ/location
  listenAngularRouter() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event) => {
        const queryParams = this.urlSerializer.parse(event.url).queryParams;
        const queryStringPanel = queryParams.panel;
        const queryStringModal = queryParams.modal;
        const state: RoutingStateType = this.initialState();

        if (queryStringPanel) {
          const { page, action, idEmplacement, idEmplacementPhoto } =
            extractPanelRoutingInformation(queryStringPanel);

          const emplacementStatus = queryParams.status as
            | EMPLACEMENT_STATUS
            | undefined;

          const { lat, lng, forceFullWidth } = queryParams;
          const forceOpenSearch = queryParams.open === 'true' || false;
          const familyIds = queryParams.familyIds
            ? queryParams.familyIds.split(',')
            : [];

          state.page = new PageState({
            page,
            action,
            id_emplacement: idEmplacement,
            id_emplacement_photo: idEmplacementPhoto,
            emplacement_status: emplacementStatus,
            latitude: lat,
            longitude: lng,
            forceOpenSearch,
            source_event: event,
            familyIds,
            forceFullWidth: forceFullWidth,
          });
        }

        if (queryStringModal) {
          const { page, action, idFirst, idSecond } =
            extractModalRoutingInformation(queryStringModal);
          state.modal = new ModalState({
            page,
            action,
            idFirst,
            idSecond,
            idGroupe: queryParams.groupe,
          });
        }

        this.saveState(state);
        this.routingSubject$.next(state);
      });
  }

  saveState(state: RoutingStateType) {
    setToLocalStorage('routingState', state);
  }

  getRouting() {
    return this.routingSubject$.getValue();
  }

  isCreateCurrentAction() {
    return (
      this.getRouting().page?.page === URL_PANEL_ACTION.CREATE ||
      this.getRouting().page?.page === URL_PANEL_ACTION.CHOOSE_ADD
    );
  }

  setActionPage(page: URL_PANEL_ACTION | undefined) {
    if (page) {
      const oldState = this.routingSubject$.getValue();
      const newState = new RoutingStateType(
        new PageState({
          ...oldState.page,
          page,
        }),
        oldState.modal
      );
      this.routingSubject$.next(newState);
    }
  }

  setActionDisplay(action: URL_PANEL_DISPLAY_ACTION | undefined) {
    if (action) {
      const oldState = this.routingSubject$.getValue();
      const newState = new RoutingStateType(
        new PageState({
          ...oldState.page,
          action,
        }),
        oldState.modal
      );
      this.routingSubject$.next(newState);
    }
  }

  navigateEmplacementPhoto(idEmplacement: string, idEmplacementPhoto: string) {
    const page = this.getRouting().page?.page;
    this.focus('emplacement');
    return this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        panel: `${page}${idEmplacement}/${URL_PANEL_DISPLAY_ACTION.PHOTOS}/${idEmplacementPhoto}`,
      },
    });
  }
  navigateEmplacementPhotoCreate(idEmplacement: string) {
    const page = this.getRouting().page?.page;

    this.focus('emplacement');
    return this.router.navigate(['/'], {
      queryParams: {
        queryParamsHandling: 'merge',
        panel: `${page}${idEmplacement}/${URL_PANEL_DISPLAY_ACTION.PRISE_PHOTO}`,
      },
    });
  }
  navigateHome() {
    return this.router.navigate(['/'], {
      queryParams: {},
    });
  }
  navigateEmplacement(
    idEmplacement: string,
    forceFullWidth: boolean | undefined = undefined
  ) {
    this.focus('emplacement');
    return this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        panel: `${URL_PANEL_ACTION.DETAIL}${idEmplacement}/${URL_PANEL_DISPLAY_ACTION.DETAIL}`,
        forceFullWidth: forceFullWidth,
      },
    });
  }
  navigateEmplacementCreate(
    latlng: LngLat | undefined = undefined,
    uuid: string = uuidv4(),
    familyIds: string[] = [],
    forceFullWidth: boolean | undefined = undefined
  ) {
    const panel = `${URL_PANEL_ACTION.CREATE}${uuid}/${URL_PANEL_DISPLAY_ACTION.LOCATION_SELECTOR}`;
    const implatationState = this.implantationModeState.getImplantationMode();

    let status = EMPLACEMENT_STATUS.NON_POSE;
    if (implatationState.mode === 'implantation') {
      status = EMPLACEMENT_STATUS.NON_POSE;
    }
    if (implatationState.mode === 'releve') {
      status = EMPLACEMENT_STATUS.POSE;
    }

    this.focus('emplacement');
    return this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        panel: panel,
        status,
        ...(latlng ? { lat: latlng.lat, lng: latlng.lng } : {}),
        ...(familyIds.length > 0 ? { familyIds: familyIds.join(',') } : {}),
        forceFullWidth: forceFullWidth,
      },
    });
  }

  navigateCurrentEmplacement(additionnalQueryParams: any = {}) {
    const pageState = this.getRouting().page;
    if (!pageState?.hasIdEmplacement()) {
      // @TODO : Throw error
      return;
    }

    this.focus('emplacement');
    return this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        panel: `${pageState.page}${pageState.id_emplacement}/${URL_PANEL_DISPLAY_ACTION.DETAIL}`,
        ...additionnalQueryParams,
      },
    });
  }

  navigateCurrentEmplacementPhotoCreate(
    direct: boolean = false,
    id_emplacement: string | null = null
  ) {
    const state = this.getRouting().page;
    if (!state?.id_emplacement) {
      if (id_emplacement) {
        this.navigateEmplacement(id_emplacement);
      }
      return;
    }
    if (!direct)
      return this.router.navigate(['/'], {
        queryParamsHandling: 'merge',
        queryParams: {
          panel: `${state.page}${state.id_emplacement}/${URL_PANEL_DISPLAY_ACTION.PRISE_PHOTO}`,
        },
      });

    this.focus('emplacement');
    return this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        panel: `${state.page}${state.id_emplacement}/${URL_PANEL_DISPLAY_ACTION.PRISE_PHOTO_DIRECT}`,
      },
    });
  }

  navigateEmplacementLocationSelector(idEmplacement: string) {
    const state = this.getRouting().page;
    this.focus('emplacement');
    return this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        panel: `${state?.page}${idEmplacement}/${URL_PANEL_DISPLAY_ACTION.LOCATION_SELECTOR}`,
      },
    });
  }

  clearEmplacement() {
    this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        panel: undefined,
        familyIds: null,
        lat: null,
        lng: null,
        status: null,
        open: null,
        forceFullWidth: null,
      },
    });
  }

  doModalNav(
    page: URL_MODAL_PAGE | undefined,
    itemId: string | null,
    create = false,
    id_groupe: string | null = null
  ) {
    this.focus('modale');
    if (itemId && page) {
      return this.router.navigate(['/'], {
        queryParamsHandling: 'merge',
        queryParams: {
          modal: `${page}${itemId}${create ? '/add' : ''}`,
          ...(id_groupe ? { groupe: id_groupe } : {}),
        },
      });
    }
    return this.router.navigate(['/'], {
      queryParamsHandling: 'merge',
      queryParams: {
        modal: page,
      },
    });
  }
  navigateStock(
    itemId: string | null = null,
    create = false,
    id_groupe: string | null = null
  ) {
    return this.doModalNav(URL_MODAL_PAGE.STOCK, itemId, create, id_groupe);
  }
  navigateMateriel(
    itemId: string | null = null,
    create = false,
    id_groupe: string | null = null
  ) {
    return this.doModalNav(URL_MODAL_PAGE.MATERIEL, itemId, create, id_groupe);
  }
  navigateBatiment(batimentId: string | null = null, create = false) {
    return this.doModalNav(URL_MODAL_PAGE.BATIMENT, batimentId, create);
  }
  navigateParametre() {
    return this.doModalNav(URL_MODAL_PAGE.PARAMETRES, null);
  }
  navigateIntervention(itemId: string | null = null) {
    return this.doModalNav(URL_MODAL_PAGE.INTERVENTION, itemId);
  }
  navigateTag(itemId: string | null = null) {
    return this.doModalNav(URL_MODAL_PAGE.TAG, itemId);
  }
  createUrlTreeTag(itemId: string) {
    const serializedUrl = this.router.serializeUrl(
      this.router.createUrlTree([], {
        queryParams: {
          modal: `${URL_MODAL_PAGE.TAG}${itemId}`,
        },
      })
    );
    return `${environment.FRONT_URL}${serializedUrl.replace('/?', '?')}`;
  }
  navigateCurrentUser() {
    return this.doModalNav(URL_MODAL_PAGE.MON_COMPTE, null);
  }
  navigateUser(itemId: string | null = null) {
    return this.doModalNav(URL_MODAL_PAGE.MES_GROUPES, itemId);
  }
  navigateClearModal() {
    return this.doModalNav(undefined, null);
  }

  focus(type: 'emplacement' | 'modale') {
    setTimeout(() => {
      this.focusService.setFocusedElement(type);
    }, 0);
  }
}
