import { Injectable, OnDestroy } from '@angular/core';
import { EventsService } from '../services/events.service';
import { isNotConformeComposantV2 } from '../services/data-accessor.service';
import { UserService } from './user.service';
import { ToolsService } from './tools.service';
import {
  getDirectParentFamilyForProductId,
  getImageFromFamiliesRecursive,
  getProductFromFamilies,
  getProductImageFromFamilies,
  recursiveFindFamilyFromFamiliesId,
} from '../utils/productfamilies-utils.service';
import { getProductPathFromCache } from '../utils/product/getProductPathFromCache';
import {
  CartStore,
  CartStoreItem,
  CartSubformeStoreType,
  StoreProductLine,
} from '@types_custom/cart-store';
import { ProductpropertiesStateService } from '../state/productproperties-state.service';
import { ProductfieldsStateService } from '../state/productfields-state.service';
import { get } from 'lodash-es';
import { ComposantType } from '@types_custom/composant';
import { ProductFamiliesType, ProductType } from '@types_custom/ProductType';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import {
  emplacementDocType,
  composantDocType,
} from '../db/schemas/emplacement.schema';
import { AuthenticationService } from './authentication.service';
import { getComposantBadConformiteString } from '../utils/composant/getComposantBadConformiteString';
import { ReglementationsStateService } from '../state/reglementations-state.service';
import { environment } from './../../environments/environment';
import { getDesignationComposant } from '../pipes/products/getDesignationComposant';

const GET_EMPTY_CART = '&empty_cart=';

@Injectable({
  providedIn: 'root',
})
export class CartService implements OnDestroy {
  // private store: CartStore | undefined;
  storeSubject = new BehaviorSubject<Record<string, CartStore>>({});
  store$ = this.storeSubject.asObservable();
  getStore$(x: string) {
    return this.store$.pipe(
      map((store) => {
        return store[x];
      })
    );
  }

  private readonly boutiqueLink =
    environment.FRONT_URL + '.netlify/functions/fill-cart?products=';

  userId: any;
  user: any;

  private subscriptions: Subscription[] = [];

  constructor(
    public AT: ToolsService,
    private auth: AuthenticationService,
    private events: EventsService,
    private UserP: UserService,
    private productpropertiesState: ProductpropertiesStateService,
    private productfieldsState: ProductfieldsStateService,
    private reglementationsState: ReglementationsStateService
  ) {
    this.userId = this.auth.getUserId();
    this.subscriptions.push(
      ...[
        this.events.subscribe('stat:go_to_website_by_ids', (ids: any) => {
          this.goToWebsiteByIds(ids);
        }),

        this.UserP.getUserByIdObs(this.userId).subscribe((data: any) => {
          if (data) {
            this.user = data;
          }
        }),
      ]
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getStore() {
    return this.storeSubject.getValue();
  }

  calculate(id: string, emplacements: any, families: ProductFamiliesType[]) {
    const store: CartStore = {};

    const properties = this.productpropertiesState.getProductproperties();
    const productfields = this.productfieldsState.getProductfields();
    const reglementations = this.reglementationsState.getReglementations();

    if (emplacements && emplacements.length > 0) {
      for (const emplacement of emplacements) {
        emplacement.composants.forEach((composant: any) => {
          // Traitement normal via le product du composant
          if (composant.product) {
            const isNotConforme = isNotConformeComposantV2(
              composant,
              null,
              families,
              properties,
              productfields,
              reglementations
            );

            this.doTheComposantCount(
              store,
              emplacement.id,
              composant,
              families,
              isNotConforme
            );
            return;
          }

          // Exception pour les composants importés via Mapillary
          if (composant.type) {
            const altFamily = recursiveFindFamilyFromFamiliesId(
              composant.type,
              families
            );
            if (!altFamily) return;
            this.doMapillaryImportedEmplacement(
              store,
              altFamily,
              emplacement.id,
              composant,
              families
            );
          }
        });
      }

      Object.keys(store).forEach((key) => {
        store[key].conforme.list.sort((a: any, b: any) => {
          return a.product.localeCompare(b.product);
        });
        store[key].nonConforme.list.sort((a: any, b: any) => {
          return a.product.localeCompare(b.product);
        });
      });
    }

    // this.storeSubject.next(this.store);
    const actualStore = this.storeSubject.getValue();
    this.storeSubject.next({
      ...actualStore,
      [id]: { ...store },
    });
  }

  doMapillaryImportedEmplacement(
    store: CartStore,
    family: ProductFamiliesType,
    emplacementsId: string,
    composant: ComposantType,
    families: ProductFamiliesType[]
  ) {
    // Wé je sais c'est dégueu mais bon, pas besoin de modifier la fonction tri pour le mettre en dernier
    const familyName = '‏Importés (Non traités)‏';
    if (!store.hasOwnProperty(familyName)) {
      store[familyName] = {
        nonConforme: {
          count: 0,
          total: 0,
          list: [],
        },
        conforme: {
          count: 0,
          total: 0,
          list: [],
        },
        count: 0,
        total: 0,
        name: familyName,
        childrens: {},
      };
    }

    const alreadyListIndex = store[familyName].nonConforme.list.findIndex(
      (item) => item.product === family.name
    );
    const familyPrice = family.price?.price || 0;
    if (alreadyListIndex === -1) {
      store[familyName].nonConforme.list.push({
        shortcut: family.name,
        image: getImageFromFamiliesRecursive(family.id, families),
        product: family.name,
        unit_type: family.unit,
        unit_price: familyPrice,
        count: composant.quantity,
        nb_emplacement: 1,
        id_emplacement: [emplacementsId],
        ids_composant: [composant.id],
        total_price: multiply(composant.quantity, familyPrice),
        comment: '',
      });
    } else {
      store[familyName].nonConforme.list[alreadyListIndex].nb_emplacement++;
      store[familyName].nonConforme.list[alreadyListIndex].count +=
        composant.quantity;
      store[familyName].nonConforme.list[alreadyListIndex].total_price +=
        multiply(composant.quantity, familyPrice);
      store[familyName].nonConforme.list[alreadyListIndex].id_emplacement.push(
        emplacementsId
      );
      store[familyName].nonConforme.list[alreadyListIndex].ids_composant.push(
        composant.id
      );
    }
    store[familyName].nonConforme.count++;
    store[familyName].nonConforme.total += familyPrice;
  }

  doTheComposantCount(
    store: CartStore,
    emplacementId: string,
    composant: composantDocType,
    families: ProductFamiliesType[],
    isNotConforme: boolean
  ) {
    if (!composant.product) return;
    if (composant.deleted_at) return;

    const productPath = getProductPathFromCache(composant.product.id, families);
    const product = getProductFromFamilies(composant.product.id, families);
    const unit =
      getDirectParentFamilyForProductId(composant.product.id, families)?.unit ??
      '';

    if (!product) return;
    const priceProduct = product.price.price;

    this.doRecursiveFromFamily(
      productPath,
      0,
      store,
      priceProduct,
      isNotConforme,
      product,
      unit,
      emplacementId,
      composant,
      families
    );
  }

  private doRecursiveFromFamily(
    productPath: any,
    i: number,
    storePart: any,
    priceProduct: number,
    isNotConforme: boolean,
    product: ProductType,
    unit: string,
    emplacementId: string,
    composant: composantDocType,
    families: ProductFamiliesType[]
  ) {
    const firstFamilyFromTree = families[parseInt(productPath[0])];
    const fam =
      i === 0
        ? firstFamilyFromTree
        : get(firstFamilyFromTree, productPath.slice(1, i * 2 + 1).join('.'));
    if (!fam) return;

    ['Tous', fam].forEach((family) => {
      const familyName = typeof family === 'string' ? 'Tous' : family.name;

      if (!storePart) {
        storePart = {};
      }
      if (!storePart[familyName]) {
        storePart[familyName] = this.initStatStoreItem(familyName);
      }

      this.increaseCountAndTotal(
        storePart[familyName],
        multiply(priceProduct, composant.quantity)
      );

      if (isNotConforme) {
        this.doRecursiveFromProduct(
          storePart[familyName].nonConforme,
          product,
          emplacementId,
          composant,
          unit,
          families
        );
      } else {
        this.doRecursiveFromProduct(
          storePart[familyName].conforme,
          product,
          emplacementId,
          composant,
          unit,
          families
        );
      }

      if (
        typeof family !== 'string' &&
        family.children_productfamilies?.length > 0 &&
        i < productPath.length
      ) {
        this.doRecursiveFromFamily(
          productPath,
          i + 1,
          storePart[familyName].childrens,
          priceProduct,
          isNotConforme,
          product,
          unit,
          emplacementId,
          composant,
          families
        );
      }
    });
  }

  private doRecursiveFromProduct(
    subStore: CartSubformeStoreType,
    product: ProductType,
    emplacementId: string,
    composant: composantDocType,
    unit: string,
    families: ProductFamiliesType[]
  ) {
    const productPrice = product.price.price;
    const comment = getComposantBadConformiteString(
      composant,
      this.productpropertiesState.getProductproperties(),
      this.productfieldsState.getProductfields(),
      this.reglementationsState.getReglementations()
    );

    const index = subStore.list.findIndex(
      (item) => item.product === product.name && item.comment === comment
    );

    if (index === -1) {
      subStore.list.push(
        this.generateCartStoreItem(
          emplacementId,
          composant,
          comment,
          product,
          families,
          unit,
          composant.quantity,
          productPrice
        )
      );
    } else {
      subStore.list[index].nb_emplacement++;
      subStore.list[index].total_price += multiply(
        composant.quantity,
        productPrice
      );
      subStore.list[index].count += composant.quantity;
      subStore.list[index].id_emplacement.push(emplacementId);
    }
    this.increaseCountAndTotal(
      subStore,
      multiply(productPrice, composant.quantity)
    );
  }

  private generateCartStoreItem(
    emplacementId: string,
    composant: composantDocType,
    comment: string,
    product: ProductType,
    families: ProductFamiliesType[],
    unit: string,
    quantity: number,
    productPrice: number
  ): StoreProductLine {
    const designation = getDesignationComposant(composant);

    return {
      shortcut: product.shortcut,
      image: getProductImageFromFamilies(product.id, families),
      product: designation ?? product.name,
      unit_type: unit,
      unit_price: productPrice,
      count: quantity,
      comment: comment,
      nb_emplacement: 1,
      id_emplacement: [emplacementId],
      total_price: multiply(productPrice, quantity),
      ids_composant: [composant.id],
    };
  }

  private increaseCountAndTotal(
    cartStore: CartStoreItem | CartSubformeStoreType,
    price: number
  ) {
    cartStore.count++;
    cartStore.total += price;
  }

  private initStatStoreItem(familyName: string): {
    nonConforme: CartSubformeStoreType;
    conforme: CartSubformeStoreType;
    total: number;
    count: number;
    name: string;
    childrens: CartStore;
  } {
    return {
      nonConforme: {
        count: 0,
        total: 0,
        list: [],
      },
      conforme: {
        count: 0,
        total: 0,
        list: [],
      },
      count: 0,
      total: 0,
      name: familyName,
      childrens: {},
    };
  }

  constructWebsiteUrlByIds(cateContainer: any, emplacements: any) {
    return emplacements.reduce((arr: any, emplacement: any) => {
      emplacement.composants.reduce((innerArr: any, composant: any) => {
        if (composant.product?.shortcut) {
          const index = innerArr.findIndex(
            (el: any) => el.shortcut == composant.product.shortcut
          );
          if (index == -1) {
            innerArr.push({
              shortcut: composant.product.shortcut,
              quantite: 1,
            });
          } else {
            innerArr[index].quantite++;
          }
        }
        return innerArr;
      }, arr);
      return arr;
    }, cateContainer);
  }

  constructWebsiteUrlFromCateContainer(cateContainer: any) {
    const parameters: any = [];
    cateContainer.forEach((el: any) => {
      parameters.push('' + el.shortcut + ',' + el.quantite + ',');
    });
    return parameters;
  }

  openCart() {
    const emptyCart = false;
    const userParameter = '&id=' + this.user.email;
    const link = this.boutiqueLink + userParameter + GET_EMPTY_CART + emptyCart;

    window.open(link, '_blank');
  }

  openCatalog() {
    const userParameter = '&id=' + this.user.email;
    const link =
      this.boutiqueLink + '&redirect=notre-catalogue' + userParameter;
    window.open(link, '_blank');
  }

  goToWebsiteByProducts(products: any) {
    const emptyCart = false;
    const tmp = products.reduce((arr: any, product: any) => {
      const index = arr.findIndex((el: any) => el.shortcut == product.shortcut);
      if (index == -1) {
        arr.push({
          shortcut: product.shortcut,
          quantite: 1,
        });
      } else {
        arr[index].quantite++;
      }
      return arr;
    }, []);

    const parameters = this.constructWebsiteUrlFromCateContainer(tmp);
    const userParameter = '&id=' + this.user.email;

    const link =
      this.boutiqueLink +
      parameters.join('|') +
      userParameter +
      GET_EMPTY_CART +
      emptyCart;

    window.open(link, '_blank');
  }

  goToWebsiteByIds(emplacements: emplacementDocType[], emptyCart = false) {
    const coupleShortcutQte = this.constructWebsiteUrlByIds([], emplacements);
    this.callBoutiqueByShortcutAndQuantite(coupleShortcutQte, emptyCart);
  }

  callBoutiqueByShortcutAndQuantite(
    coupleShortcutQte: any,
    emptyCart: boolean
  ) {
    const parameters =
      this.constructWebsiteUrlFromCateContainer(coupleShortcutQte);
    const userParameter = '&id=' + this.user.email;

    const link =
      this.boutiqueLink +
      parameters.join('|') +
      userParameter +
      GET_EMPTY_CART +
      emptyCart;

    window.open(link, '_blank');
  }

  proxyExportMapPng() {
    return new Promise((resolve) => {
      this.events.publish('toggleMapNumber', true);
      setTimeout(() => {
        this.events.publish('exportMapPng');
        resolve(null);
      }, 1000);
    });
  }

  proxyExportListeCsv() {
    return new Promise((resolve) => {
      this.events.publish('exportListeCsv');
      resolve(null);
    });
  }
}

export const multiply = (a: number, b: number): number => {
  return Math.round(a * b * 100) / 100;
};
