import { getDefaultLayoutClusterCount } from '../../components/objet-osmap/getDefaultLayoutClusterCount';
import { getDefaultLayoutIconLineString } from '../../components/objet-osmap/getDefaultLayoutUnclustered';
import { getDefaultPaintCluster } from '../../components/objet-osmap/getDefaultPaintCluster';
import { getDefaultPaintClusterCount } from '../../components/objet-osmap/getDefaultPaintClusterCount';
import {
  MIN_ZOOM_LINE_OR_POLYGON,
  MAP_CLUSTER_LINE_COLOR,
} from './mapbox-constants';
import { mapboxLayerOptions } from './mapbox.interface';

export const LINE_SYMBOL_HOME: mapboxLayerOptions = {
  id: 'line-symbol',
  source: 'lines_source',
  type: 'symbol',
  layer: {
    id: 'line-symbol',
    source: 'lines_source',
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: getDefaultLayoutIconLineString('coucou'),
    minzoom: MIN_ZOOM_LINE_OR_POLYGON,
  },
  zIndex: 'z-index-2',
};
export const LINE_LINE_HOME: mapboxLayerOptions = {
  id: 'line-features',
  source: 'lines_source',
  type: 'line',
  layer: {
    id: 'line-features',
    source: 'lines_source',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: 'visible',
    },
    paint: {
      'line-color': '#BF93E4',
      'line-width': 5,
    },
    minzoom: MIN_ZOOM_LINE_OR_POLYGON,
  },
  zIndex: 'z-index-2',
};
export const LINE_CLUSTER_HOME: mapboxLayerOptions = {
  id: 'line-clusters',
  source: 'lines_points_source',
  type: 'circle',
  layer: {
    id: 'line-clusters',
    type: 'circle',
    source: 'lines_points_source',
    filter: ['has', 'point_count'],
    paint: getDefaultPaintCluster(MAP_CLUSTER_LINE_COLOR),
  },
  zIndex: 'z-index-2',
};
export const LINE_CLUSTER_COUNT_HOME: mapboxLayerOptions = {
  id: 'line-cluster-count',
  source: 'lines_points_source',
  type: 'symbol',
  layer: {
    id: 'line-cluster-count',
    type: 'symbol',
    source: 'lines_points_source',
    filter: ['has', 'point_count'],
    layout: getDefaultLayoutClusterCount(),
    paint: getDefaultPaintClusterCount(),
  },
  zIndex: 'z-index-2',
};
