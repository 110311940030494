import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';
import { productpropertiesDocType } from '../../db/schemas/productproperties.schema';
import {
  composantDocType,
  composantProductfieldDocType,
} from '../../db/schemas/emplacement.schema';
import { getLimiteMaxAgeComposant } from './getLimiteMaxAgeComposant';
import { productfieldDocType } from '../../db/schemas/productfields.schema';

export function getComposantDateRenouvellement(
  composant: composantDocType,
  properties: productpropertiesDocType[],
  productfields: productfieldDocType[]
) {
  const field = productfields.find(
    (productfield: productfieldDocType) =>
      productfield.type === PRODUCTFIELD_TYPE.DATE_FABRICATION
  );
  if (!field) return new Date();
  if (composant.composant_productfields.length === 0) return new Date();

  const fieldDateFabrication = composant.composant_productfields.find(
    (productfield: composantProductfieldDocType) => {
      return productfield.productfield.id === field.id;
    }
  );

  if (!fieldDateFabrication?.value) {
    return new Date();
  }

  const maxAge = getLimiteMaxAgeComposant(composant, properties);
  const dateRenew = new Date(fieldDateFabrication.value);

  dateRenew.setFullYear(dateRenew.getFullYear() + maxAge);
  
  return dateRenew;
}
