import { getLatLngFromPoint } from './getLatLngFromPoint';
import { getLatLngFromLineString } from './getLatLngFromLineString';
import { getLatLngFromPolygon } from './getLatLngFromPolygon';
import { getLatLngFromMultilineString } from './getLatLngFromMultilineString';
import { isGeometryValid } from '../../utils/geometry/isGeometryValid';
import { CenterAbbr } from '../../components/emplacement/geo-qrcode-emplacement/CenterAbbr';

/**
 * Calculates the center coordinates and zoom level from a given GeoJSON geometry.
 *
 * @param geometry - The GeoJSON geometry object to calculate the center from.
 * @returns An object containing the latitude, longitude, and zoom level, or undefined if the geometry is invalid.
 *
 * @throws Will throw an error if the geometry type is unsupported.
 */
export const getCenterFromGeometry = (
  geometry: GeoJSON.Geometry
): CenterAbbr | undefined => {
  let center;
  let bounds: [[number, number], [number, number]];

  if (!isGeometryValid(geometry)) {
    return;
  }

  switch (geometry.type) {
    case 'Point':
      center = getLatLngFromPoint(geometry);
      bounds = getBoundsFromPoint(geometry);
      break;
    case 'LineString':
      center = getLatLngFromLineString(geometry);
      bounds = getBoundsFromLineString(geometry);
      break;
    case 'MultiLineString':
      center = getLatLngFromMultilineString(geometry);
      bounds = getBoundsFromMultilineString(geometry);
      break;
    case 'Polygon':
      center = getLatLngFromPolygon(geometry);
      bounds = getBoundsFromPolygon(geometry);
      break;
    default:
      throw new Error('Unsupported geometry type');
  }

  const zoom = getZoomLevelFromBounds(bounds, 500, 500);
  const zoomLevel = zoom > 18 ? 18 : zoom;
  return { lat: center.lat, lng: center.lng, zoom: zoomLevel };
};

/**
 * Calculates the bounding box for a given GeoJSON Point geometry.
 *
 * @param geometry - A GeoJSON Point object containing the coordinates.
 * @returns A tuple representing the bounding box with the same point as both corners.
 */
const getBoundsFromPoint = (
  geometry: GeoJSON.Point
): [[number, number], [number, number]] => {
  const [lng, lat] = geometry.coordinates;
  return [
    [lat, lng],
    [lat, lng],
  ];
};

/**
 * Calculates the bounding box for a given LineString geometry.
 *
 * @param geometry - The LineString geometry for which to calculate the bounding box.
 * @returns A tuple representing the southwest and northeast corners of the bounding box.
 */
const getBoundsFromLineString = (
  geometry: GeoJSON.LineString
): [[number, number], [number, number]] => {
  const coordinates = geometry.coordinates;
  return getBoundsFromCoordinates(coordinates);
};

/**
 * Calculates the bounding box for a given MultiLineString geometry.
 *
 * @param geometry - A GeoJSON MultiLineString object containing the coordinates.
 * @returns A tuple representing the bounding box with the format [[minLng, minLat], [maxLng, maxLat]].
 */
const getBoundsFromMultilineString = (
  geometry: GeoJSON.MultiLineString
): [[number, number], [number, number]] => {
  const coordinates = geometry.coordinates.flat();
  return getBoundsFromCoordinates(coordinates);
};

/**
 * Computes the bounding box for a given GeoJSON Polygon geometry.
 *
 * @param geometry - The GeoJSON Polygon geometry for which to compute the bounding box.
 * @returns A tuple representing the bounding box of the polygon, where the first element is the
 *          southwest corner [longitude, latitude] and the second element is the northeast corner
 *          [longitude, latitude].
 */
const getBoundsFromPolygon = (
  geometry: GeoJSON.Polygon
): [[number, number], [number, number]] => {
  const coordinates = geometry.coordinates[0];
  return getBoundsFromCoordinates(coordinates);
};

/**
 * Calculates the bounding box (minimum and maximum latitude and longitude) from an array of coordinates.
 *
 * @param coordinates - An array of GeoJSON.Position, where each position is a tuple of [longitude, latitude].
 * @returns A tuple containing two tuples: the first is the minimum latitude and longitude, and the second is the maximum latitude and longitude.
 *
 * @example
 * ```typescript
 * const coordinates: GeoJSON.Position[] = [
 *   [102.0, 0.5],
 *   [103.0, 1.5],
 *   [104.0, 0.0],
 *   [105.0, 1.0]
 * ];
 * const bounds = getBoundsFromCoordinates(coordinates);
 * console.log(bounds); // Output: [[0.0, 102.0], [1.5, 105.0]]
 * ```
 */
const getBoundsFromCoordinates = (
  coordinates: GeoJSON.Position[]
): [[number, number], [number, number]] => {
  let minLat = Infinity,
    minLng = Infinity,
    maxLat = -Infinity,
    maxLng = -Infinity;
  coordinates.forEach(([lng, lat]) => {
    if (lat < minLat) minLat = lat;
    if (lat > maxLat) maxLat = lat;
    if (lng < minLng) minLng = lng;
    if (lng > maxLng) maxLng = lng;
  });
  return [
    [minLat, minLng],
    [maxLat, maxLng],
  ];
};

/**
 * Calculates the appropriate zoom level for a map given the geographical bounds and the map's dimensions.
 *
 * @param bounds - A tuple containing two tuples, each with two numbers representing the minimum and maximum latitude and longitude.
 * @param mapWidth - The width of the map in pixels.
 * @param mapHeight - The height of the map in pixels.
 * @returns The calculated zoom level as a number.
 */
const getZoomLevelFromBounds = (
  bounds: [[number, number], [number, number]],
  mapWidth: number,
  mapHeight: number
): number => {
  const [[minLat, minLng], [maxLat, maxLng]] = bounds;
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const latRad = (lat: number) => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  const latFraction = (latRad(maxLat) - latRad(minLat)) / Math.PI;
  const lngDiff = maxLng - minLng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = Math.floor(
    Math.log2(mapHeight / WORLD_DIM.height / latFraction)
  );
  const lngZoom = Math.floor(
    Math.log2(mapWidth / WORLD_DIM.width / lngFraction)
  );

  return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
};
