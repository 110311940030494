import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  Input,
  inject,
} from '@angular/core';
import { EmplacementDetailStateService } from './../../../state/emplacement-detail-state.service';
import { EventsService } from './../../../services/events.service';
import { isConformeEmplacementV2 } from './../../../services/data-accessor.service';
import { ProductSearchStateService } from './../../../state/product-search-state.service';
import { emplacementDocType } from '../../../db/schemas/emplacement.schema';
import { lineString, polygon } from '@turf/helpers';
import { RoutingState } from '../../../state/routing-state.service';
import { findFirstPhoto } from './../../../pipes/emplacements/emplacement-first-photo.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormatNumberPipe } from '../../../pipes/format-number.pipe';
import { EmplacementFirstPhotoPipe } from '../../../pipes/emplacements/emplacement-first-photo.pipe';
import {
  doTagsParseCommon,
  EmplacementZonesPipe,
} from '../../../pipes/emplacements/emplacement-zones.pipe';
import { UgauListitemComponent } from '../../ugau-listitem/ugau-listitem.component';
import { UgauListComponent } from '../../ugau-list/ugau-list.component';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { NgIf, AsyncPipe, NgForOf, KeyValuePipe } from '@angular/common';
import { UgauMapComponent } from '../../ugau-map/ugau-map.component';
import { ToolsService } from './../../../services/tools.service';
import { CommonStateService } from './../../../state/commun-state.service';
import area from '@turf/area';
import length from '@turf/length';
import { Platform } from '@ionic/angular/standalone';
import { openInMap } from '../geo-qrcode-emplacement/openInMap';
import { MatListModule } from '@angular/material/list';
import { TagService } from '../../../services/tag/tag.service';
import { InterventionsStateService } from '../../../state/interventions-state.service';
import { tagDocType } from '../../../db/schemas/tag.schema';
import { MatIconModule } from '@angular/material/icon';
import {
  trackByIdFn,
  trackByKeyFn,
} from '../../../services/trackByFunctions.utils';
import { getCenterFromGeometry } from '../../../services/mapbox/getCenterFromGeometry';
import { openInBlank } from '../geo-qrcode-emplacement/openInBlank';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Center } from '../../ugau-map/Center';

@Component({
  selector: 'app-location',
  templateUrl: './location.container.html',
  styleUrls: ['./location.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UgauMapComponent,
    NgIf,
    UgauButtonComponent,
    UgauListComponent,
    UgauListitemComponent,
    AsyncPipe,
    EmplacementZonesPipe,
    EmplacementFirstPhotoPipe,
    FormatNumberPipe,
    MatListModule,
    NgForOf,
    MatIconModule,
    KeyValuePipe,
    MatTooltipModule,
  ],
})
export class LocationContainer {
  trackByKey = trackByKeyFn;
  trackById = trackByIdFn;

  @Input() forceDisableMapAndMove = false;

  private destroyRef = inject(DestroyRef);
  private platform = inject(Platform);
  private tagService = inject(TagService);
  private interventionService = inject(InterventionsStateService);

  isMobile = this.tools.isMobile();
  labelBtnMove!: string;
  classes: string = '';
  polygonArea?: number | undefined;
  lineLength?: number | undefined;
  geojson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  center!: Center | null;

  tags: [string, tagDocType[]][] = [];

  _emplacement: emplacementDocType | null = null;
  @Input() set emplacement(emplacement: emplacementDocType | null) {
    this._emplacement = emplacement;

    if (!emplacement?.geometry.type) {
      this.labelBtnMove = $localize`Placer`;
      return;
    }
    this.labelBtnMove = $localize`Déplacer`;

    switch (emplacement.geometry.type) {
      case 'Point':
        this.polygonArea = undefined;
        this.lineLength = undefined;
        break;
      case 'LineString':
        const line = lineString(emplacement.geometry.coordinates);
        this.lineLength = length(line, { units: 'meters' });
        this.polygonArea = undefined;
        break;
      case 'MultiLineString':
        const multiLine = lineString(emplacement.geometry.coordinates.flat());
        this.lineLength = length(multiLine, { units: 'meters' });
        this.polygonArea = undefined;
        break;
      case 'Polygon':
        const poly = polygon(emplacement.geometry.coordinates);
        this.polygonArea = area(poly);
        this.lineLength = undefined;
    }

    this.setGeojson(emplacement.geometry);
    const tagsTmp = doTagsParseCommon(
      emplacement,
      this.tagService,
      this.interventionService
    );
    if (typeof tagsTmp !== 'string') {
      this.tags = tagsTmp;
    }

    const families = this.productSearchState.getOriginalTree();
    const { properties, fields, reglementations } = this.common.getCommon();

    if (
      isConformeEmplacementV2(
        emplacement,
        families,
        properties,
        fields,
        reglementations
      )
    ) {
      this.classes =
        emplacement.emplacement_photos.length > 0 ? ' conforme' : ' no-photos';
    } else {
      this.classes = ' non-conforme';
    }
  }
  get emplacement() {
    return this._emplacement;
  }

  @HostBinding('class.map-big') bigMap: boolean = false;

  constructor(
    private detailState: EmplacementDetailStateService,
    private routingState: RoutingState,
    private events: EventsService,
    private productSearchState: ProductSearchStateService,
    private tools: ToolsService,
    private common: CommonStateService
  ) {
    this.detailState.width.isFullwidth$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isFullwidth) => {
        this.bigMap = isFullwidth;
      });
  }

  setGeojson(geometry: GeoJSON.Geometry) {
    this.geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry,
          properties: {},
        },
      ],
    };
    const center = getCenterFromGeometry(geometry);
    if (!center) {
      return;
    }
    this.center = {
      latitude: +center.lat,
      longitude: +center.lng,
    };
  }

  changeLocation() {
    if (!this.emplacement?.id) {
      return;
    }
    this.routingState.navigateEmplacementLocationSelector(this.emplacement.id);
  }

  addEmplacementPhoto() {
    this.routingState.navigateCurrentEmplacementPhotoCreate(
      false,
      this.emplacement?.id
    );
  }

  showFirstEmplacementPhoto() {
    if (!this.emplacement?.id) return;

    const photo = findFirstPhoto(this.emplacement);
    if (!photo)
      return this.routingState.navigateEmplacement(this.emplacement.id);

    this.routingState.navigateEmplacementPhoto(this.emplacement.id, photo.id);
  }

  showOnGlobalMap() {
    if (!this.emplacement) {
      return;
    }

    this.events.publish('map:center_emplacement', {
      item: this.emplacement,
    });

    if (this.isMobile) {
      this.routingState.clearEmplacement();
      this.routingState.navigateHome();
    }
  }

  openInMap() {
    openInMap(this.emplacement, this.platform.is('ios'));
  }

  openInStreetView() {
    if (!this.emplacement?.geometry) return;
    const center = getCenterFromGeometry(this.emplacement.geometry);

    if (!center) return;

    const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${center.lat},${center.lng}`;
    openInBlank(streetViewUrl);
  }

  openTagEdit(tag: tagDocType) {
    this.routingState.navigateTag(tag.id);
  }
}
