import { Injectable } from '@angular/core';
import { LngLatBounds, Map, Marker, Popup } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { emplacementDocType } from '../db/schemas/emplacement.schema';
import { getShortcutPr } from '../pipes/getShortcutPr';
import { getInfoPrFromIgn } from './mapbox/mapbox-ign.service';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public mapGlobal: Map | null = null;

  private showNumeroSubject$ = new BehaviorSubject(false);
  showNumero$ = this.showNumeroSubject$.asObservable();
  toggleShowNumero() {
    const actualValue = !this.showNumeroSubject$.getValue();
    this.showNumeroSubject$.next(actualValue);
  }
  getShowNumero() {
    return this.showNumeroSubject$.getValue();
  }
  hideShowNumero() {
    this.showNumeroSubject$.next(false);
  }

  private showNumeroEnsembleSubject$ = new BehaviorSubject(false);
  showNumeroEnsemble$ = this.showNumeroEnsembleSubject$.asObservable();
  toggleShowNumeroEnsemble() {
    const actualValue = !this.showNumeroEnsembleSubject$.getValue();
    this.showNumeroEnsembleSubject$.next(actualValue);
  }
  getShowNumeroEnsemble() {
    return this.showNumeroEnsembleSubject$.getValue();
  }
  hideShowNumeroEnsemble() {
    this.showNumeroEnsembleSubject$.next(false);
  }

  flyToEmplacement(emplacement: emplacementDocType | null) {
    if (
      !emplacement ||
      (emplacement.geometry.type !== 'Polygon' &&
        emplacement.geometry.type !== 'LineString' &&
        emplacement.geometry.type !== 'Point')
    )
      return;

    const bounds = new LngLatBounds();
    if (emplacement.geometry.type === 'LineString') {
      emplacement.geometry.coordinates.forEach((e: any) => {
        bounds.extend(e);
      });
    }
    if (emplacement.geometry.type === 'Polygon') {
      emplacement.geometry.coordinates.forEach((e: any) => {
        e.forEach((f: any) => {
          bounds.extend(f);
        });
      });
    }
    if (emplacement.geometry.type === 'Point') {
      bounds.extend(emplacement.geometry.coordinates as any);
    }

    if (!bounds.isEmpty()) this.mapGlobal?.fitBounds(bounds, { padding: 50 });
  }

  zoomOnPR(pr: any) {
    if (!pr) return;

    const coords = pr.position;
    const id = coords.join('_');

    const bounds = new LngLatBounds();
    bounds.extend(coords);
    if (bounds.isEmpty()) return;

    this.mapGlobal?.fitBounds(bounds, { padding: 50, maxZoom: 18 });
    // Next time the map is idle, we add the marker
    this.mapGlobal?.once('idle', () => {
      if (!this.mapGlobal) return;

      if (!markerCache[id]) {
        markerCache[id] = new Marker().setLngLat(coords).addTo(this.mapGlobal);

        getInfoPrFromIgn(coords[1], coords[0], this.mapGlobal.getZoom())
          .then((text) => {
            let isRemoving = false; // Le delete du marker retrigger le close du popup
            const popup = new Popup({ offset: 25 }).on('close', function () {
              if (isRemoving) {
                return;
              }

              isRemoving = true;
              markerCache[id]?.remove();
              markerCache[id] = undefined;
              isRemoving = false;
            });

            if (text.includes('<h5>')) {
              // Si text contient un <h5> alors c'est un PR IGN
              popup.setHTML(text);
            } else {
              // Fallback avec le shortcut
              popup.setText(getShortcutPr(pr));
            }
            markerCache[id]?.setPopup(popup).togglePopup();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }

  zoomTemporaryPopup(
    coords: [number, number],
    text: string,
    zoom: number = 18
  ) {
    const id = coords.join('_');

    const bounds = new LngLatBounds();
    bounds.extend(coords);
    if (bounds.isEmpty()) return;

    this.mapGlobal?.fitBounds(bounds, { padding: 50, maxZoom: zoom });
    // Next time the map is idle, we add the marker
    this.mapGlobal?.once('idle', () => {
      if (!this.mapGlobal) return;

      if (!markerCache[id]) {
        markerCache[id] = new Marker().setLngLat(coords).addTo(this.mapGlobal);

        if (text && text !== '') {
          const popup = new Popup({ offset: 25 }).setText(text);
          markerCache[id]?.setPopup(popup).togglePopup();
        }

        setTimeout(() => {
          markerCache[id]?.remove();
          markerCache[id] = undefined;
        }, 5000);
      }
    });
  }
}

const markerCache: Record<string, Marker | undefined> = {};
