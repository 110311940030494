import { LngLatBounds } from 'mapbox-gl';
import { getBoundsCenter } from './getBoundsCenter';

/**
 * Retrieves the central latitude and longitude from a given GeoJSON Polygon.
 *
 * @param {GeoJSON.Polygon} geometry - The GeoJSON Polygon from which to extract the central coordinates.
 * @returns {LngLatLike} The central latitude and longitude of the polygon.
 */
export function getLatLngFromPolygon(geometry: GeoJSON.Polygon) {
  const bounds = new LngLatBounds();
  geometry.coordinates.forEach((coords) => {
    coords.forEach((subCoords) => {
      bounds.extend([subCoords[0], subCoords[1]]);
    });
  });

  return getBoundsCenter(bounds);
}
